<template>
  <v-container fluid>
    <v-row
      dense
      align="center"
      class="mt-2 mb-2"
    >
      <v-col cols="auto">
        <span v-if="mouBy === 'shipper'" class="font-weight-bold">
          {{$_strings.mou.MOU_SHIPPER}}
        </span>
        <span v-if="mouBy === 'transporter'" class="font-weight-bold">
          {{$_strings.mou.MOU_3PL}}
        </span>
      </v-col>
      <v-col v-if="showBtnCreateMoU" cols="12" sm="auto">
        <v-btn
          color="primary"
          small
          @click="routeNavigate({ action: 'create' })"
        >
          {{$_strings.mou.ADD_MOU}}
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <csv-list-button
          :mouBy="mouBy"
          :key="mouBy"
        />
      </v-col>
      <v-col
        class="ml-md-auto"
        cols="12"
        sm="6"
        md="3"
      >
        <v-row
          dense
          justify="end"
          align="center"
        >
          <v-col cols="auto">
            <filter-list
              :filters="filters"
              @fetchMoUList="key_table_data_list += 1"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              hide-details
              outlined
              v-model="companyName"
              class="caption"
              @keyup="setSearch"
              :placeholder="searchPlaceHolder"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="setSearch">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <table-data-list
      :key="key_table_data_list"
      :filters="filters"
      :mouBy="mouBy"
    />
  </v-container>
</template>

<script>
import { dateFormat, skipEmptyStringObject } from '../../helper/commonHelpers';
import TableDataList from './TableDataList.vue';
import FilterList from './FilterList.vue';
import CsvListButton from './CsvListButton.vue';

export default {
  name: 'MoUList',
  components: {
    CsvListButton,
    FilterList,
    TableDataList,
  },
  data() {
    return {
      key_table_data_list: 0,
      companyName: this.$route.query.companyName || '',
      filters: {
        companyName: this.$route.query.companyName || '',
        mouNo: this.$route.query.mouNo || '',
        abbreviation: this.$route.query.abbreviation || '',
        mouPeriodMonths: this.$route.query.mouPeriodMonths || '',
        monthsExpired: this.$route.query.monthsExpired || '',
        activeStatus: this.$route.query.activeStatus || '',
        approvalStatus: this.$route.query.approvalStatus || '',
      },
    };
  },
  computed: {
    mouBy() {
      const [,,, path] = this.$route.path.split('/');
      return path;
    },
    currentPath() {
      return this.$route.path;
    },
    searchPlaceHolder() {
      if (this.mouBy === 'transporter') {
        return 'Cari nama rekanan 3PL';
      }
      return 'Cari nama shipper';
    },
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
    showBtnCreateMoU() {
      return this.userAccess.canCreate;
    },
  },
  watch: {
    mouBy() {
      this.companyName = '';
      this.filters = {
        companyName: '',
        mouNo: '',
        abbreviation: '',
        mouPeriodMonths: '',
        monthsExpired: '',
        activeStatus: '',
        approvalStatus: '',
      };
    },
  },
  methods: {
    dateFormat,
    skipEmptyStringObject,
    setSearch(e) {
      if (e.type === 'click' || e.keyCode === 13) {
        if (this.companyName !== this.$route.query.companyName) {
          this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              companyName: this.companyName,
              page: 1,
            },
          });
          this.filters.companyName = this.companyName;
        }
        this.key_table_data_list += 1;
      }
    },
    routeNavigate({ action }, step = 0) {
      const params = {
        action,
      };
      let name = 'shipper-mou-detail';
      if (this.mouBy === 'transporter') name = 'transporter-mou-detail';
      this.$router.push({
        name,
        params,
        query: {
          step,
        },
      });
    },
  },
};
</script>
